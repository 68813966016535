import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { count, isDefined } from '@components/config/helpers';
import useCartHandler from '@components/_hooks/Cart/useCartHandler';
import useCartActions from '@components/_hooks/Cart/useCartActions';
import usePadActions from '@components/_hooks/Cart/usePadActions';

const CategoryProductPriceLabels = dynamic(() => import('./PriceSection/CategoryProductPriceLabels'));
const CategoryProductImage = dynamic(() => import('./CategoryProductImage'));
const CategoryProductVariants = dynamic(() => import('./Variants/CategoryProductVariants'), { ssr: false });
const CategoryProductVariantImageContainer = dynamic(() => import('./Variants/CategoryProductVariantImageContainer'), { ssr: false });
const CategoryProductPriceSection = dynamic(() => import('./PriceSection/CategoryProductPriceSection'), { ssr: true });
const PadSelector = dynamic(() => import('@components/Components/Reusables/PadSelector'), { ssr: false });

const CardProps = {
    raised: false,
    elevation: 2,
    sx: {
        marginBottom: {
            xs: '0.5rem',
            md: 0
        },
        borderLeft: '1px solid lightgrey',
        borderRight: '1px solid lightgrey',
        minWidth: '100%',
        maxHeight: {
            lg: 'fit-content',
            md: 'fit-content',
            sm: '90%'
        },
        minHeight: {
            lg: 'fit-content',
            md: 'fit-content',
            sm: '100%'
        },
    }
};

const CategoryProductTile = ({ 
        props, 
        long_term, 
        product, 
        product_idx, 
        source, 
        show_images = true,
        gridProps = {}
    }) => {
    const show_product_variants = useSelector((state) => state.globalReducer.template_settings.show_product_variants);


    const [isVisible, setIsVisible] = useState(!show_images ? product_idx < 8 : true);
    const productRef = useRef(null);

    useEffect(() => {
        const observerCallback = (entries, observer) => {
          entries.forEach(entry => {
            if(!isVisible && entry.isIntersecting){
                setIsVisible(entry.isIntersecting);
            }
          });
        };
    
        const observer = new IntersectionObserver(observerCallback, {
            rootMargin: '50px 0px', 
            threshold: [0, 0.1, 1.0] 
        });
    
        if (productRef.current) {
          observer.observe(productRef.current);
        }
    
        return () => {
          observer.disconnect();
        };
      }, [productRef.current]);


    const {
        addToCart
    } = useCartHandler();

    const {
        handleQtyChange,
        qty,
        setQty,
    } = useCartActions();

    const {
        padSelector,
        setShowPadSelector,
        handlePadSelector,
        select,
        handleExtra,
        cancelPadSelection
    } = usePadActions();

    const productData = useMemo(() => ({
        image: product.image,
        product_name: product.product_name,
        product_code: product.product_code,
        on_offer: product.on_offer,
        section_slug: product.section_slug,
        product_slug: product.product_slug,
        is_designer_original: product.is_designer_original,
        product_idx,
        long_term,
    }), [product]);

    const productProps = useMemo(() => ({
        product_id: product.product_id,
        product_name: product.product_name,
        product_code: product.product_code,
        product_slug: product.product_slug,
        bow_color_option: product.bow_color_option,
        pad_color_option: product.pad_color_option,
        image: product.image,
        color: product.color,
        section_slug: product.section_slug,
        on_offer: product.on_offer,
        poa_price: product.poa_price,
        price_breaks: product.price_breaks,
        product_price: product.product_price,
        new_price: product.new_price,
        addons: product.addons,
        extras: product.extras,
        pad_options: product.pad_options,
    }), [product]);

    const variantData = useMemo(() => ({
        long_term,
        source,
    }), [long_term, source]);

    const priceLabels = useMemo(() => ({
        product_name: product.product_name,
        product_code: product.product_code,
        section_slug: product.section_slug,
        product_slug: product.product_slug,
    }), [product]);

    const priceSection = useMemo(() => ({
        product: productProps,
        page: 'category',
        long_term,
        sectionID: 2,
        source,
        handleQtyChange,
        qty,
        addToCart,
        handleExtra
    }), [productProps, long_term, source, handleQtyChange, qty, addToCart, handleExtra]);

    const PadProps = useMemo(() => ({
        product: productProps,
        props: {
            extras: props.extras
        },
        onClose: cancelPadSelection,
        handlePadSelector: handlePadSelector,
        select,
        source
    }), [productProps, props.extras, cancelPadSelection, handlePadSelector, select, source]);

    return (
        <Grid
            item
            {...gridProps}
            ref={productRef}
            className={`product ${isVisible ? 'visible' : 'invisible'}`}
            sx={{
                marginTop: 0,
            }}
        >
        <Card 
            sx={{
                marginBottom: '0.5rem',
                borderLeft: '1px solid lightgrey',
                borderRight: '1px solid lightgrey',
                minWidth: '100%',
                maxHeight: 'fit-content',
                minHeight: 'fit-content',
            }}
            raised={false}
            elevation={2}
        >
            <CategoryProductImage {...productData} />

            <CardContent className={`mt-0 mb-0 pb-0 pr-2 pl-2 p-0`}>
                {(source !== 'search' && show_product_variants === 'yes') && (
                    <Box
                        sx={{
                            height: 'fit-content',
                            minHeight: '58px'
                        }}
                        className='d-flex w-100 mt-0 mb-0'
                    >
                        {isDefined(product.product_variants) && count(product.product_variants) > 0 ? (
                            <CategoryProductVariants
                                {...variantData}
                                product={{
                                    product_id: product.product_id,
                                    product_slug: product.product_slug,
                                    product_variants: product.product_variants,
                                }}
                            />
                        ) : (
                            <CategoryProductVariantImageContainer
                                {...variantData}
                                variant={{
                                    product_variant_product_id: product.product_variant_product_id,
                                    product_slug: product.product_slug,
                                    section_slug: product.section_slug,
                                    product_variant_label: product.product_variant_label,
                                    product_name: product.product_name,
                                    image: product.image,
                                }}
                            />
                        )}
                    </Box>
                )}

                <CategoryProductPriceLabels {...priceLabels} />
                <CategoryProductPriceSection {...priceSection} />

                {padSelector && padSelector.product_id === product.product_id && padSelector.open &&
                    (
                        <PadSelector {...PadProps} />
                    )}
            </CardContent>
        </Card>
        </Grid>

    );
};

export default CategoryProductTile;
